import { Component, computed, Signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../services/state.service';
import { SafeHtmlPipe } from '../../services/safe-html.pipe';
import { IonButtons, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonContent } from '@ionic/angular/standalone';

@Component({
  selector: 'app-article',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
  imports: [SafeHtmlPipe, IonButtons, IonHeader, IonToolbar, IonTitle, IonMenuButton, IonContent],
})
export class ArticlePageComponent {
  title: string | undefined = undefined;
  content: string = '';
  icon: string | undefined = undefined;
  treatmentPlace: Signal<string | undefined>;
  medicalTrail: Signal<string | undefined>;

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService,
    private router: Router,
  ) {
    this.medicalTrail = computed(() => {
      const medicalTrail = this.stateService.medicalTrail();
      if (medicalTrail?.includes('enrolled-with-video')) {
        return 'enrolled-with-video';
      } else if (medicalTrail?.includes('enrolled-without-video')) {
        return 'enrolled-without-video';
      } else {
        return medicalTrail;
      }
    });
    this.treatmentPlace = this.stateService.currentTreatmentPlace;

    this.setupArticleContent();
  }

  setupArticleContent() {
    const article = this.stateService.getArticle(this.route.snapshot.paramMap.get('slug') || '');
    if (!article) {
      this.router.navigate(['']);
      return;
    }
    const { menutitle, content, icon } = article;
    this.title = menutitle;
    this.content = content;
    this.icon = icon;
  }
}
